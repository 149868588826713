.fassai-basic-page {
  font-family: Arial, sans-serif;
  
}

.hero-section-document {
  position: relative;
  background-image: url('./images/type-of-fssai-license-registration.webp');
  background-size: cover;
  background-position: center;
  color: #fff;
  text-align: center;
  padding: 60px 20px;
  overflow: hidden;
}

.hero-section-document::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.651); /* Black overlay with 50% opacity */
  z-index: 1;
}

.hero-section-document .hero-content {
  position: relative;
  z-index: 2; /* Ensures content is above the overlay */
}

.hero-content h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.hero-content p {
  font-size: 1rem;
  margin-bottom: 30px;
}

.apply-button {
  background-color: #ff7f00;
  color: #fff;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.content-section {
  display: flex;
  padding: 40px 20px;
  gap: 20px;
  width: 100%;
}

.license-info {
  flex: 2;
  padding: 20px;
}

.license-info h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.license-info p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.license-info ul {
  list-style-type: disc;
  margin-left: 20px;
}

.license-info ul li {
  margin-bottom: 10px;
}

.info-image {
  max-width: 70%;
  border-radius: 5px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 28px;
  }

  .hero-content p {
    font-size: 16px;
  }

  .apply-button {
    padding: 12px 25px;
    font-size: 14px;
  }

  .content-section {
    flex-direction: column;
    padding: 20px;
  }

  .registration-form {
    margin-bottom: 20px;
  }

  .license-info h2 {
    font-size: 20px;
  }

  .license-info p {
    font-size: 14px;
  }

  .submit-button {
    padding: 10px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .hero-content h1 {
    font-size: 24px;
  }

  .hero-content p {
    font-size: 14px;
  }

  .apply-button {
    padding: 10px 20px;
    font-size: 12px;
  }

  .license-info h2 {
    font-size: 18px;
  }

  .license-info p {
    font-size: 13px;
  }

  .submit-button {
    padding: 8px;
    font-size: 13px;
  }
}
