.home {
  
}
  
.hero-section {
  position: relative;
  background-image: url('./images/fssai-registration-for-online-food-business.webp');
  object-fit: cover;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  height: 50vw;
  background-position: center;
  color: #ffffff;
  overflow: hidden; /* Ensure overlay does not spill out of the section */
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.549); /* Black color with 62.5% opacity */
  z-index: 1; /* Place overlay above the background image */
}

.hero-section > * {
  position: relative;
  z-index: 2; /* Ensure content is above the overlay */
}

.hero-section h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 22vw;
}

.hero-section p {
  font-size: 1.2rem;
  margin-bottom: 30px;
  text-align: center;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.cta-button,
.ca-button {
  background: linear-gradient(90deg, #5582fe, #012a70); /* Gradient colors */
  color: #ffffff;
  padding: 20px 30px;
  text-decoration: none;
  border-radius: 30px;
  font-weight: bold;
  text-align: center;
  margin: 0 10px;
  cursor: pointer;
}

.cta-button:hover,
.ca-button:hover {
  background-color: #c0392b;
}

.fssai-info {
  padding: 50px;
  background-color: #f0f4f8;
  text-align: center;
}

.section-title {
  font-size: 2rem;
  color: #000000;
}

.section-description {
  font-size: 1rem;
  color: #666;
  max-width: 900px;
  margin: 0 auto 30px auto;
}



.fssai-info .cta-button:hover {
  background-color: #008f47;
}

.fssai-license-types {
  padding: 50px;
  background-color: #ffffff;
  text-align: center;
}

.license-cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.license-card {
  background: linear-gradient(90deg, #5582fe, #012a70); /* Gradient colors */
  border-radius: 10px;
  padding: 20px;
  flex: 1;
  margin: 10px;
  max-width: 350px;
  text-align: center;
  box-shadow: 2px 2px 2px #333333da;
}

.license-icon {
  width: 70px;
  margin-bottom: 15px;
}

.license-card h3 {
  font-size: 1.5rem;
  color: white;
  margin-bottom: 10px;
}

.license-card p {
  font-size: 1rem;
  color: white;
}

/* Responsive Design */

/* Medium Devices (Tablets, 768px and up) */
@media (max-width: 768px) {
  .hero-section {
    height: 60vh; /* Adjust height for tablets */
  }

  .hero-section h1 {
    font-size: 2rem;
    margin-top: 30vw;
  }

  .hero-section p {
    font-size: 1rem;
  }

  .cta-button,
  .ca-button {
    font-size: 1rem;
    padding: 15px 20px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .buttons {
    flex-direction: column;
    align-items: center;
  }

  .license-cards {
    flex-direction: column;
    align-items: center;
  }

  .license-card {
    margin-bottom: 20px;
    max-width: 90%;
  }
}

/* Small Devices (Mobile Phones, 480px and up) */
@media (max-width: 480px) {
  .hero-section {
    height: 70vh; /* Adjust height for small devices */
  }

  .hero-section h1 {
    font-size: 1.8rem;
    margin-top: 35vw;
  }

  .hero-section p {
    font-size: 0.9rem;
  }

  .cta-button,
  .ca-button {
    font-size: 0.9rem;
    padding: 10px 15px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .license-card {
    padding: 15px;
    margin-bottom: 15px;
    max-width: 95%;
  }

  .license-card h3 {
    font-size: 1.3rem;
  }

  .license-card p {
    font-size: 0.9rem;
  }
}
