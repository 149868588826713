.header {
  background: transparent;
  padding: 0.5rem 1rem;
  box-shadow: none;
  position: fixed;
  width: 100%;
  transition: background 0.3s ease, box-shadow 0.3s ease;
  top: 0;
  z-index: 1000; /* Ensure the header is always on top */
}

.header.scrolled {
  background: white; /* Solid background on scroll */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.437);
  color: black;
}

.header.scrolled .navbar-nav .nav-link {
  color: #000; /* Change link color to black on scroll */
}

.header .logo {
  max-height: 90px; /* Adjust based on your logo size */
}

.header .navbar-nav .nav-link {
  color: white; /* Adjust text color to contrast with the gradient */
  font-weight: bold;
}

.header .navbar-nav .nav-link:hover {
  color: #ddd; /* Hover color for nav links */
}

/* Customize the hamburger menu icon */
.header .navbar-toggler {
  border: none; /* Remove default border */
}

.header .navbar-toggler-icon {
  background-color: white; /* Set background color for the toggler */
  width: 30px; /* Adjust width */
  height: 30px; /* Adjust height */
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  border-radius: 5px;
 
}

.header .navbar-toggler-icon::before { 
  font-size: 1rem; /* Adjust icon size */
  padding: 5px;
}

/* Mobile-specific styles */
@media (max-width: 767px) {
  .header {
    background: white; /* Solid background on mobile screens */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.437);
  }

  .header .navbar-nav {
    text-align: center;
    background-color: white; /* Ensure dropdown menu has a solid background */
  }

  .header .navbar-nav .nav-link {
    margin: 0.5rem 0;
    color: black; /* Consistent color on mobile screens */
  }

  .header .navbar-nav .nav-link:hover {
    color: #666; /* Slightly darker hover color on mobile */
  }
}
