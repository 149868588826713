/* src/components/AboutUs.css */

.about-us-container {
  font-family: 'Arial', sans-serif;
  color: #333;
  line-height: 1.6;
}

.about-us-hero {
  position: relative;
  background: url('./images//about.txt') no-repeat center center/cover;
  color: white;
  text-align: center;
  padding: 80px 20px;
  height: 25vw;
  overflow: hidden; /* Ensure the overlay doesn't go outside the container */

}

.about-us-hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.616); /* Black background with 50% opacity */
  z-index: 1; /* Ensure the overlay is above the background image but below the text */
}

.hero-content {
  position: relative; /* Ensure content is above the overlay */
  max-width: 800px;
  margin: 0 auto;
  z-index: 2; /* Ensure text is above the overlay */
}

.hero-content h1 {
  font-size: 3rem;
  font-weight: bold;
  margin-top: 5vw;
}

.hero-content p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.about-us-content {
  max-width: 100vw;
  margin: 40px auto;
  padding: 20px;
  box-shadow: 4px 4px 6px black;
  width: 70%;
}

.about-us-content h2 {
  font-size: 2rem;
  font-family: Arial, Helvetica, sans-serif;
  color: #000000;
  margin-bottom: 15px;
}

.about-us-content p {
  font-size: 1.2em;
  margin-bottom: 20px;
}
/* Benefits Section Styles */
.benefits-section {
  max-width: 90%;
  margin: 40px auto;
  padding: 20px;
  background-color: #f9f9f9;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.36);
  border-radius: 8px;
}

.benefits-section h2 {
  font-size: 2rem;
  color: #000000;
  margin-bottom: 20px;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.477);
  font-weight: bold;
}

.benefits-list {
  list-style-type: none;
  padding: 0;
}

.benefits-list li {
  font-size: 1.2em;
  margin-bottom: 10px;
  line-height: 1.5;
}

.benefits-list li strong {
  color: #333;
  
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 2.5em;
    margin-top: 4vh;
  }
  .about-us-content {
    width: 100%;
    margin: 40px auto;
    padding: 20px;
    box-shadow: 4px 4px 6px black;
    width: 70%;
  }
  .hero-content p {
    font-size: 1em;
  }

  .about-us-content h2 {
    font-size: 1.8em;
  }

  .about-us-content p {
    font-size: 1em;
  }
  .benefits-section {
    width: 100%;
    padding: 20px;
  }

  .benefits-section h2 {
    font-size: 1.8rem;
  }

  .benefits-list li {
    font-size: 1em;
  }
}
