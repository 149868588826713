.fassai-registration-page {
    font-family: 'Arial', sans-serif;
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background-color: #fdfdfd;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 1.8rem;
    color: #333;
  }
  
  .fassai-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .form-group label {
    font-weight: bold;
    font-size: 1rem;
    color: #555;
  }
  
  .form-group input,
  .form-group select,
  .form-group textarea {
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    color: #333;
    background-color: #f7f7f7;
    transition: border 0.3s ease;
  }
  
  .form-group input:focus,
  .form-group select:focus,
  .form-group textarea:focus {
    border-color: #4caf50;
    outline: none;
  }
  
  .radio-group {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
  
  .radio-group label {
    font-size: 0.9rem;
    color: #333;
  }
  
  .form-group-inline {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .checkbox-group {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 10px;
  }
  
  .checkbox-group label {
    font-size: 0.9rem;
    color: #333;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .form-actions {
    display: flex;
    justify-content: flex-end;
  }
  
  .submit-button {
    padding: 12px 20px;
    border: none;
    background-color: #4caf50;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #45a049;
  }
  
  @media (max-width: 768px) {
    .form-group-inline {
      flex-direction: column;
    }
  
    .checkbox-group {
      grid-template-columns: 1fr;
    }
  }
  