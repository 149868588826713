.contact-us {
  font-family: Arial, sans-serif;
  color: #333;
}

.contact-header {
  position: relative;
  text-align: center;
  color: white;
}

.header-image {
  width: 100%;
  height: 20vw;
  object-fit: cover;
}

.contact-header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(6, 1, 1, 0.621); /* Black background with 50% transparency */
  z-index: 1; /* Ensure overlay is above the image */
}

.header-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  font-weight: bold;
  z-index: 2;
  padding: 10px;
  text-align: center;
}

.contact-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  margin-top: 20px;
}

.contact-form, .contact-details {
  width: 48%;
  margin: 5vw 0;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.666);
  padding: 5vw;
  box-sizing: border-box;
}

.contact-form form {
  display: flex;
  flex-direction: column;
}

.contact-form label {
  margin: 10px 0 5px;
}
.contact-head{
  font-size: 2rem;
  font-weight: bold;
  text-transform:uppercase;
}
.chead{
  font-size: 1.5rem;
  font-weight: bold;
}

.contact-form input, .contact-form textarea {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.contact-form button {
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #0056b3;
}

.contact-details {
  text-align: center;
}

.fssai-info {
  margin: 40px 0;
  text-align: center;
}

.section-title {
  font-size: 2rem;
  font-weight: bold;
}

.section-description {
  max-width: 800px;
  margin: 0 auto;
  padding: 10px;
  font-size: 1.1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .header-text {
    font-size: 2rem;
  }

  .contact-content {
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
  }

  .contact-form, .contact-details {
    width: 100%;
    margin: 20px 0;
    padding: 20px;
  }

  .contact-form input, .contact-form textarea {
    padding: 8px;
  }

  .contact-form button {
    padding: 12px;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .header-text {
    font-size: 1.5rem;
  }

  .contact-form, .contact-details {
    padding: 15px;
  }

  .contact-form button {
    font-size: 0.9rem;
    padding: 10px;
  }
}
