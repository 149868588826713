/* Services.css */
.services-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}
.service-header{
  position: relative;
  text-align: center;
  color: white;
}
.wwp {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
  color: #000000;
  font-weight: bold;
  border-bottom: 2px solid #ccc;
}

.services-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.service-item {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.service-item:hover {
  background-color: #e0e0e0;
  transform: scale(1.02);
}

.service-item h2 {
  font-size: 18px;
  margin: 0;
}
.service-header {
  position: relative;
  text-align: center;
}

.header-overlay {
  position: relative;
}

.header-image {
  width: 100%;
  height: auto;
}

.service-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.778); /* Black with 50% transparency */
  z-index: 1;
}

.header-text {
  position: relative;
  z-index: 2;
  color: white;
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .header-text{
    font-size: 1rem;
  }
  .service-header{
    position: relative;
    width: 100%;
    color: white;
  }
  .services-container {
      padding: 15px;
  }

  .wwp {
      font-size: 1.8rem;
      margin-bottom: 15px;
  }

  .services-list {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media (max-width: 480px) {
  .services-container {
      padding: 10px;
  }

  .wwp {
      font-size: 1.5rem;
      margin-bottom: 10px;
  }

  .services-list {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      gap: 15px;
  }

  .service-item {
      padding: 10px;
  }

  .service-item h2 {
      font-size: 16px;
  }
}
