/* src/components/Footer.css */
.footer {
  background: linear-gradient(90deg, #5582fe, #01163b); /* Gradient colors */
  padding: 40px 20px;
    color: #333;
    border-top: 1px solid #e1e1e1;
  }
  
  .footer-container {
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: white;
  }
  
  .footer-logo {
    flex: 1;
    margin-bottom: 20px;
  }
  
  .footer-logo img {
   max-width: 200px;
  }
  
  .footer-logo p {
    margin-top: 10px;
    color: #fbf9f9;
  }
  
  .footer-links,
  .footer-contact,
  .footer-social,
  .footer-cta {
    flex: 1;
    margin-bottom: 20px;
    color: white;
    margin-right: 1vw;
    text-align: center;
  }
  
  .footer-links h2,
  .footer-contact h2,
  .footer-social h2 {
    font-size: 1.5em;
    margin-bottom: 15px;
    color: #f7f8f9;
  }
  
  .footer-links ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .footer-links li {
    margin-bottom: 10px;
    color: white;
  }
  
  .footer-links a {
    text-decoration: none;
    color:white;
    transition: color 0.3s;
  }
  
  .footer-links a:hover {
    color: #f4f9ff;
  }
  
  .footer-contact p {
    margin: 5px 0;
  }
  
  .footer-social {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  
  .footer-social .social-icons {
    display: flex;
    gap: 15px;
    margin-left: 4vw;
  }
  
  .footer-social a {
    color: white;
    font-size: 1.5em;
    transition: color 0.3s;
  }
  
  .footer-social a:hover {
    color: #007bff;
  }
  
  .footer-cta {
    text-align: center;
  }
  
  .footer-cta .button {
    background-color: #fff;
    color: black;
    padding: 15px 25px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.2em;
    transition: background-color 0.3s;
  }
  
  .footer-cta .cta-button:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-logo {
      text-align: center;
    }
  
    .footer-links,
    .footer-contact,
    .footer-social,
    .footer-cta {
      text-align: center;
     
    }
  }
  